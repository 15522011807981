import { fetchNotifications } from "@/api";
import { getWsUrl } from "@/api/utils";
import type { NotificationItem, Noty } from "@/shared/types";
import { MessageType, OrderStatus } from "@/shared/enums";

interface GetNotyParams {
  limit?: number;
  offset?: number;
}

export const useNotificationsStore = defineStore("notifications", () => {
  const notifications = ref<NotificationItem[]>([]);
  const loading = ref(false);
  const WS = ref<ReturnType<typeof useWebSocket>>();
  const lastPage = ref(false);

  const lastUnread = computed(() => {
    return notifications.value.filter((n) => !n.isRead && n.hash !== "");
  });

  const statusWatcher = ref();
  const dataWatcher = ref();

  const getNotifications = async (params?: GetNotyParams) => {
    if (lastPage.value) return;
    loading.value = true;
    const passedParams = {
      offset: params?.offset || 0,
      limit: params?.limit || 50,
    };
    const res = await fetchNotifications(passedParams);
    notifications.value = [...notifications.value, ...res];
    if (passedParams.limit > res.length) lastPage.value = true;
    loading.value = false;
  };

  const notificationsConnect = async () => {
    notificationsDisconnect();
    return new Promise(function (resolve, reject) {
      const URL = getWsUrl("/ws/global");
      WS.value = useWebSocket(URL, {
        autoReconnect: true,
      });

      statusWatcher.value = watch(
        () => WS.value?.status,
        () => {
          const status = WS.value?.status as any;
          if (status === "CLOSED") {
            return reject("Connection closed");
          }
          resolve(WS.value);
          statusWatcher.value?.();
        }
      );

      dataWatcher.value = watch(() => WS.value?.data, onNotyMessage);
    });
  };

  const notificationsDisconnect = () => {
    statusWatcher.value?.();
    dataWatcher.value?.();
    notifications.value = [];
    WS.value?.close?.();
  };

  const getNotyTitle = (noty: any) => {
    switch (noty.type) {
      case MessageType.SystemMessage:
        return noty.message === "statusChanged" ||
          noty.message === "moderatorJoined"
          ? `Order #${noty.orderId}`
          : "System message";
      case MessageType.UserMessage:
        return `Order #${noty.orderId}`;
      default:
        return noty.type;
    }
  };

  const { t } = useI18n();

  const getNotyText = (noty: any) => {
    switch (noty.type) {
      case MessageType.SystemMessage:
        switch (noty.message) {
          case "statusChanged":
            if (noty.status === OrderStatus.AwaitingPayment) {
              return t("s-useNotifications-new-order-created");
            }
            return t("s-useNotifications-order-status-changed", {
              status: noty.status,
            });
          case "orderCreated":
            return t("s-useNotifications-order-created");
          case "orderDeleted":
            return t("s-useNotifications-order-deleted");
          case "moderatorJoined":
            return t("s-useNotifications-moderator-joined");
          case noty.attachment && noty.attachment !== "":
            return t("s-useNotifications-attached-file");
          default:
            return noty.message;
        }
      case MessageType.UserMessage:
        return `${noty.nickname}: ${noty.message}`;
      default:
        return noty.message;
    }
  };

  const onNotyMessage = (evt: any) => {
    if (!evt) return;
    const noty: Noty = JSON.parse(evt);
    // if (noty.chatType === ChatType.exchangeChat) {
    //   return;
    // }

    const title = getNotyTitle(noty);
    const text = getNotyText(noty);
    const notyPassed = {
      title,
      text,
      date: noty.date,
      hash: noty.hash,
      isRead: noty.isRead,
      baseMessage: noty,
    };
    notifications.value.unshift(notyPassed);
  };

  const sendIsRead = (messages: string[]) => {
    messages.forEach((hash) => {
      const noty = notifications.value.find((n) => n.hash === hash);
      if (noty) noty.isRead = true;
    });
    WS.value?.send?.(JSON.stringify({ readMessages: messages }));
  };

  return {
    notifications,
    loading,
    lastUnread,
    lastPage,
    getNotifications,
    notificationsConnect,
    notificationsDisconnect,
    sendIsRead,
  };
});
